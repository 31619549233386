import { get, post } from './rest';
import { FileType } from '../model/fileType';
import { PalletPassportListModel } from '../component/runs/model/talman/palletPassportModel';

const PATH_BASE = 'docs';

const docs = {
    runsBriefPdf: (runIds: number[]) => 
        post<Blob>(`${PATH_BASE}/runs/brief/pdf`, runIds),
    carrierContract: (clientId: number, contractId: number, fileType: FileType) =>
        get<Blob>(`${PATH_BASE}/client/${clientId}/carrier-contract/${contractId}/${fileType}`),
    runCarrierAgreement: (runId: number, fileType: FileType) =>
        get<Blob>(`${PATH_BASE}/run/${runId}/carrier-agreement/${fileType}`),
    runCarrierTransportationAgreement: (runId: number, fileType: FileType) =>
        get<Blob>(`${PATH_BASE}/run/${runId}/carrier-transportation-agreement/${fileType}`),
    talman: (shipmentDetailId: number, fileType: FileType) =>
        get<Blob>(`${PATH_BASE}/talman/${shipmentDetailId}/${fileType}`),
    palletPassport: (talmanId: number, listModel: PalletPassportListModel, fileType: FileType) =>
        listModel.id > 0
        ? get<Blob>(`${PATH_BASE}/pallet-passport/${listModel.id}/${fileType}`)
        : post<Blob>(`${PATH_BASE}/pallet-passport-by-talman/${talmanId}/${fileType}`, listModel),        
    talmanPalletPassportsPdf: (runId: number) =>
        get<Blob>(`${PATH_BASE}/talman-pallet-passport/${runId}/pdf`),
};

export default docs;
