import polyline from '@mapbox/polyline';

/**
 * Encodes an array of [longitude, latitude] pairs into a polyline string.
 * @param points - Array of coordinate pairs in [longitude, latitude] format.
 * @returns Encoded polyline string.
 */
const encodePolyline = (points: number[][]): string =>
    polyline.encode(points.map(([lng, lat]) => [lng, lat]));

export default encodePolyline;
