import { MimeType } from '../model/mimeType';
import util from './util';

const fileUtil = {
    prepareFormData: (file: File) => {
        const formData = new FormData();
        formData.append('file', file);
        return formData;
    },

    downloadBlob: (blob: Blob, fileName: string) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl);
    },

    printBlob: (blob: Blob, mimeType: MimeType) => {
        const mimeBlob = new Blob([blob], { type: mimeType });
        const blobUrl = window.URL.createObjectURL(mimeBlob);
        const blobWindow = window.open(blobUrl);
        blobWindow?.print();
    },

    formatDateTimeForFileName: (date: Date) => {
        return date
            ? date.toLocaleDateString('ru-RU') +
            '_' +
            util.pad(date.getHours(), 2) +
            '-' +
            util.pad(date.getMinutes(), 2) +
            '-' +
            util.pad(date.getSeconds(), 2)
            : '';        
    },

};

export default fileUtil;
