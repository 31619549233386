import L, { LatLngTuple } from 'leaflet';
import React, { useEffect } from 'react';
import { TileLayer, useMap } from 'react-leaflet';
import { IconType } from './getMarkerIcon';
import RouteWithMarkers from './RouteWithMarkers';
import RouteMarker from './RouteMarker';

import type { RouteMarkers } from './MapComponent';

type Props = {
    routePolyline: Array<[number, number]>;
    routeMarkers: RouteMarkers[];
    driversPolyline?: Array<[number, number]>;
    truck: string;
    driversCurrentPosition?: LatLngTuple;
};

const MapContent: React.FC<Props> = ({
    routeMarkers,
    routePolyline,
    driversPolyline,
    truck,
    driversCurrentPosition,
}) => {
    const map = useMap();

    useEffect(() => {
        if (routePolyline.length > 0) {
            const bounds = L.latLngBounds(routePolyline);
            map.fitBounds(bounds);
        }
    }, [routePolyline, map]);

    return (
        <>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            />

            {routeMarkers?.length > 0 && (
                <RouteWithMarkers positions={routePolyline} markers={routeMarkers} color='blue' />
            )}

            {driversPolyline && driversPolyline.length > 0 && (
                <RouteWithMarkers positions={driversPolyline as [number, number][]} color='red' />
            )}

            {truck && driversCurrentPosition && (
                <RouteMarker
                    latitude={driversCurrentPosition[1]}
                    longitude={driversCurrentPosition[0]}
                    iconType={IconType.TRUCK}
                    tooltip={truck}
                />
            )}
        </>
    );
};

export default MapContent;
